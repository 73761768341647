import React, { useState, useEffect } from "react"

import { withPrefix } from "gatsby"

import PropTypes from "prop-types"

const Asterisk = ({ scrollSpin = true, spinSpeedDivisor = 1 }) => {
  const [state, setState] = useState({ scrollHeight: 0 })

  const handleScroll = (e) => {
    const top = window.scrollY
    setState({
      scrollHeight: top,
    })
  }

  useEffect(() => {
    if (scrollSpin) {
      window.addEventListener("scroll", handleScroll)
    }

    return () => window.removeEventListener("scroll", handleScroll)
  }, [scrollSpin])

  const rotation = `rotate(${(state.scrollHeight / spinSpeedDivisor) % 360}deg)`

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <img
      src={withPrefix("/images/asterisk.svg")}
      width="40"
      alt="Thesis Asterisk"
      className="asterisk spacing--xl"
      style={{
        WebkitTransform: rotation,
        MozTransform: rotation,
        transform: rotation,
      }}
    />
  )
}

Asterisk.propTypes = {
  scrollSpin: PropTypes.bool,
  spinSpeedDivisor: PropTypes.number,
}

Asterisk.defaultProps = {
  scrollSpin: false,
  spinSpeedDivisor: 1,
}

export default Asterisk
