import React, { FC } from "react"
import Image, { ImageProps } from "./Image"

export type TeamMemberProps = {
  photo: ImageProps["imageData"]
  name: string
  role: string
  bio?: { html: string }
  expertise?: string
  languages?: string
  socials: { url: string; label: string }[]
}
const TeamMember: FC<TeamMemberProps> = ({
  photo,
  name,
  role,
  bio,
  socials,
  expertise,
  languages,
}) => (
  <div className="team-member">
    <Image imageData={photo} className="spacing---sm" />
    <h6 className="h6 sans">{name}</h6>
    <div className="spacing---sm">{role}</div>
    {bio?.html && (
      <div
        className="bio spacing---sm"
        dangerouslySetInnerHTML={{ __html: bio.html }}
      />
    )}
    {languages && (
      <p className="bio spacing---sm">
        <strong>Languages: </strong> {languages}
      </p>
    )}
    {expertise && (
      <p className="bio spacing---sm">
        <strong>Expertise: </strong>
        {expertise}
      </p>
    )}
    <div>
      {socials.map((social) => (
        <a
          className="mr-2"
          key={social.url}
          href={social.url}
          target="_blank"
          rel="noreferrer"
        >
          {social.label}
        </a>
      ))}
    </div>
  </div>
)

TeamMember.defaultProps = {
  expertise: "",
  languages: "",
  bio: { html: "" },
}

export default TeamMember
