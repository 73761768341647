import React, { FC } from "react"
import { withPrefix } from "gatsby"

const ThesisIntroducingMezoLogo: FC = () => (
  <>
  <div className="thesis-defense-introducing-logo" aria-label="INTRODUCING">
    <img src={withPrefix("/images/thesis-mezo.svg")} alt="thesis-mezo" style={{width: "300px"}}/>
  </div>
  </>
)

export default ThesisIntroducingMezoLogo
