import React from "react"
import PropTypes from "prop-types"
import { graphql, Link, withPrefix } from "gatsby"
import classNames from "classnames"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Asterisk from "../components/Asterisk"
import {
  Embellishment2,
  Embellishment3,
  Embellishment4,
  Embellishment5,
} from "../components/Embellishments"
import Layout from "../components/Layout"
import Image from "../components/Image"

import "../css/home.css"
import TeamMember, { TeamMemberProps } from "../components/TeamMember"
import ThesisIntroducingMezoLogo from "../components/ThesisIntroducingMezoLogo"

function Section1({ hero, what }) {
  return (
    <div className="section">
      <div className="background---black position---relative">
        <div className="big-x---positioner">
          <div className="big-x---wrapper">
            <img
              src={withPrefix("/images/birds-on-the-edge.jpg")}
              srcSet={`${withPrefix(
                "/images/birds-on-the-edge-800.jpg"
              )} 800w, ${withPrefix(
                "/images/birds-on-the-edge-1080.jpg"
              )} 1080w, ${withPrefix("/images/birds-on-the-edge.jpg")} 1320px`}
              sizes="(max-width: 767px) 82vw, 81vw"
              className="asterisk-top-background"
              alt=""
            />
            <img
              src={withPrefix("/images/home_big_asterisk-top_mask.png")}
              srcSet={`${withPrefix(
                "/images/home_big_asterisk-top_mask-p-500.png"
              )} 500w, ${withPrefix(
                "/images/home_big_asterisk-top_mask-p-800.png"
              )} 800w, ${withPrefix(
                "/images/home_big_asterisk-top_mask-p-1080.png"
              )} 1080w, ${withPrefix(
                "/images/home_big_asterisk-top_mask-p-1600.png"
              )} 1600w, ${withPrefix(
                "/images/home_big_asterisk-top_mask.png"
              )} 2178w`}
              sizes="(max-width: 767px) 82vw, 81vw"
              alt=""
              className="image-10"
            />
          </div>
        </div>
        <div className="home-hero-content-wrapper text---white top w-clearfix">
          <div className="home-hero-left">
            <h6
              className="h6 label spacing---lg invert"
              dangerouslySetInnerHTML={{ __html: hero.title }}
            />
            <p
              className="large"
              dangerouslySetInnerHTML={{ __html: hero.body }}
            />
          </div>
          <div className="what">
            <h1
              className="h1 spacing---sm"
              dangerouslySetInnerHTML={{ __html: what.title }}
            />
            <p
              className="home-hero-subhead medium"
              dangerouslySetInnerHTML={{ __html: what.body }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Section2({ title, contents }) {
  return (
    <div className="section overflow">
      <div className="position---relative">
        <Embellishment2 />
        <div className="big-x---positioner---bottom">
          <img
            src={withPrefix("/images/home_big_asterisk-bottom.png")}
            srcSet={`${withPrefix(
              "/images/home_big_asterisk-bottom-p-500.png"
            )} 500w, ${withPrefix(
              "/images/home_big_asterisk-bottom-p-800.png"
            )} 800w, ${withPrefix(
              "/images/home_big_asterisk-bottom-p-1080.png"
            )} 1080w, ${withPrefix(
              "/images/home_big_asterisk-bottom.png"
            )} 1560w`}
            sizes="58vw"
            alt=""
            className="image-14"
          />
        </div>
        <div className="xl---padding-12---1-col">
          <div className="xl---v-padding---1---10-col">
            <h6
              className="h6 label spacing---lg"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className="w-clearfix">
              {contents.map((content, index) => (
                <div
                  key={`asterisk-col-${index}`}
                  className={`home-asterisk---column-${index + 1}`}
                >
                  <h3
                    className="h3 spacing---sm"
                    dangerouslySetInnerHTML={{ __html: content.label }}
                  />
                  <p
                    className="justify"
                    dangerouslySetInnerHTML={{ __html: content.description }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Section3({ tabs, contents }) {
  return (
    <div className="section">
      <div className="background---black xl---padding-12---2-col text---white xl---v-padding---2">
        <div data-duration-in="150" data-duration-out="100" className="w-tabs">
          <div className="social-tabs spacing---lg w-tab-menu">
            {tabs.map((tab, index) => (
              <a
                key={`social-tab-${index}`}
                data-w-tab={`Tab ${index + 1}`}
                href={tab.url}
                className={`social-tab w-inline-block w-tab-link ${
                  index === 0 ? "w--current" : ""
                }`}
              >
                <div>{tab.label}</div>
              </a>
            ))}
          </div>
          <div className="w-tab-content">
            {contents.map((content, index) => (
              <div
                key={`social-content-${index}`}
                data-w-tab={`Tab ${index + 1}`}
                className={`w-tab-pane ${index === 0 ? "w--tab-active" : ""}`}
              >
                <div
                  className="social-blurb spacing---lg"
                  dangerouslySetInnerHTML={{ __html: content.blurb }}
                />
                <div
                  className="social-attribute"
                  dangerouslySetInnerHTML={{ __html: content.attribute }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function Section4({ title, body, projects }) {
  return (
    <div className="section">
      <div className="xl---padding-12---1-col">
        <div className="xl---v-padding---3---10-col">
          <div className="spacing---lg w-clearfix">
            <div className="our-work-col-1">
              <h6 className="h6 label">{title}</h6>
            </div>
            <div className="our-work-col-2">
              <p className="medium">{body}</p>
            </div>
          </div>
          {projects.map(({ name, photo, logo, cta }, i) => {
            // Projects swap sides and decorations based on evenness.
            const isEven = i % 2 === 0

            const logoComponent = (
              <>
                <div className="logo-notch-1" />
                <div className="logo-tab">
                  <img
                    src={withPrefix(`/images/${logo.image.relativePath}`)}
                    width="39"
                    alt={logo.alt}
                  />
                </div>
              </>
            )

            const projectImage = getImage(photo.image)

            const photoComponent = (
              <div className="work-photo">
                {projectImage && (
                  <GatsbyImage image={projectImage} alt={photo.alt} />
                )}
                {logoComponent}
              </div>
            )

            const ctaComponent = (
              <div className="work-cta fold">
                <a
                  href={cta.url}
                  className="work-cta-wrapper w-inline-block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3 className="h3 link-block-text">{cta.title}</h3>
                  <div className="cta-arrow-wrapper">
                    <img
                      src={withPrefix(`/images/${cta.icon.image.relativePath}`)}
                      alt={cta.icon.alt}
                      className="link-block-arrow"
                    />
                  </div>
                </a>
              </div>
            )

            return (
              <>
                <div
                  className={classNames(
                    isEven ? "offset" : "offset-right",
                    isEven ? "" : "position--relative",
                    "work-project",
                    "spacing---xxl",
                    "w-clearfix",
                    name.toLowerCase().replace(" ", "-")
                  )}
                >
                  {isEven ? photoComponent : ctaComponent}
                  {isEven ? ctaComponent : photoComponent}
                </div>
                {isEven ? (
                  <Asterisk
                    scrollSpin
                    spinSpeedDivisor={2 + Math.random() * 1.5}
                  />
                ) : (
                  i !== projects.length - 1 && <Embellishment2 />
                )}
              </>
            )
          })}
          <Embellishment4 />

          {/* <div className="offset-right position---relative w-clearfix">
            <div className="work-cta keep">
              <a
                href={keep.cta.url}
                target="_blank"
                rel="noopener noreferrer"
                className="work-cta-wrapper alt w-inline-block"
              >
                <h3 className="h3">{keep.cta.title}</h3>
                <div className="cta-arrow-wrapper">
                  <img
                    src={withPrefix(
                      `/images/${keep.cta.icon.image.relativePath}`,
                    )}
                    alt={keep.cta.icon.alt}
                  />
                </div>
              </a>
            </div>
            <div className="work-photo">
              <img
                src={withPrefix(`/images/${keep.photo.image.relativePath}`)}
                srcSet={`${withPrefix(
                  " /images/keep/keep-thesis-p-800.jpg",
                )} 800w, ${withPrefix(
                  "/images/keep/keep-thesis-p-1080.jpg",
                )} 1080w, ${withPrefix("/images/keep/keep-thesis.jpg")} 1320w`}
                sizes="(max-width: 767px) 88vw, (max-width: 991px) 46vw, (max-width: 2933px) 45vw, 1320px"
                alt={keep.photo.alt}
              />
              <div className="logo-notch-1" />
              <div className="logo-tab">
                <img
                  src={withPrefix(`/images/${keep.logo.image.relativePath}`)}
                  width="28"
                  alt={keep.logo.alt}
                />
              </div>
            </div>
            <Embellishment4 /> */}
        </div>
      </div>
    </div>
  )
}

function Section5({ title, body, links }) {
  return (
    <div className="section">
      <Embellishment5 />
      <div className="xl---padding-12---1-col border---top">
        <div className="xl---v-padding---3---10-col">
          <div>
            <h6 className="h6 label spacing---lg">{title}</h6>
            <h1 className="h1 spacing---lg">{body}</h1>
            <div className="assurance-wrapper w-clearfix">
              {links.map((link, index) => (
                <div
                  key={`assurance-link-${index}`}
                  className={`_2up-column ${index < 2 ? "top-row" : ""}`}
                >
                  <a href={link.url}>
                    <img
                      src={withPrefix(
                        `/images/${link.icon.image.relativePath}`
                      )}
                      width="183"
                      alt={link.icon.alt}
                      className="spacing---md"
                    />
                  </a>
                  <p dangerouslySetInnerHTML={{ __html: link.description }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ContactSection({ join, team }) {
  return (
    <div className="section">
      <div className="xl---padding-12---1-col background---black text---white position---relative">
        <div className="textured-asterisk">
          <img
            src={withPrefix("/images/asterisk-fire.png")}
            width="596"
            srcSet={`${withPrefix(
              " /images/asterisk-fire-p-500.png"
            )} 500w, ${withPrefix(
              "/images/asterisk-fire-p-800.png"
            )} 800w, ${withPrefix(
              "/images/asterisk-fire-p-1080.png"
            )} 1080w, ${withPrefix("/images/asterisk-fire.png")} 1192w`}
            sizes="(max-width: 991px) 42vw, 41vw"
            data-w-id="2a1df217-a362-1095-e536-b7c5ba194876"
            style={{ filter: "invert(100%)" }}
            alt=""
            className="animation---invert"
          />
        </div>
        <div className="xl---v-padding---3---10-col">
          <div className="join-up-wrapper spacing---xxxl">
            <h6 className="label invert h6 spacing---lg">{join.title}</h6>
            <h1 className="h1 spacing---lg top">{join.body}</h1>
            <Link to={join.link.url} className="cta invert">
              {join.link.label}
            </Link>
          </div>
        </div>
      </div>
      <div className="xl---padding-12---1-col background---x1">
        <div className="xl---v-padding---3---10-col">
          <h6 className="h6 label spacing---lg">{team.title}</h6>
          <div className="spacing---lg md---spacing---0 w-clearfix">
            {team.members.slice(0, 2).map((member: TeamMemberProps) => (
              <TeamMember
                key={`${member.name}-${member.role}`}
                name={member.name}
                role={member.role}
                socials={member.socials}
                photo={member.photo}
              />
            ))}
          </div>
          <div className="spacing---lg md---spacing---0 md---flex w-clearfix">
            {team.members.slice(2, 4).map((member, index) => (
              <div key={`member2-${index}`} className="team-member">
                <Image imageData={member.photo} className="spacing---sm" />
                <h6 className="h6 sans">{member.name}</h6>
                <div className="spacing---md">{member.role}</div>
                <div>
                  {member.socials.map((social, index) => (
                    <a
                      className="mr-2"
                      key={`member2-social-${index}`}
                      href={social.url}
                    >
                      {social.label}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="spacing---lg md---spacing---0 md---flex w-clearfix">
            {team.members.slice(4, 6).map((member, index) => (
              <div key={`member3-${index}`} className="team-member">
                <Image imageData={member.photo} className="spacing---sm" />
                <h6 className="h6 sans">{member.name}</h6>
                <div className="spacing---md">{member.role}</div>
                <div>
                  {member.socials.map((social, index) => (
                    <a
                      className="mr-2"
                      key={`member3-social-${index}`}
                      href={social.url}
                    >
                      {social.label}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="spacing---lg md---spacing---0 w-clearfix">
            {team.members.slice(6, 8).map((member, index) => (
              <div key={`member4-${index}`} className="team-member">
                <Image imageData={member.photo} className="spacing---sm" />
                <h6 className="h6 sans">{member.name}</h6>
                <div className="spacing---md">{member.role}</div>
                <div>
                  {member.socials.map((social, index) => (
                    <a
                      className="mr-2"
                      key={`member4-social-${index}`}
                      href={social.url}
                    >
                      {social.label}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="spacing---lg md---spacing---0 w-clearfix">
            {team.members.slice(9, 12).map((member, index) => (
              <div key={`member5-${index}`} className="team-member">
                <Image imageData={member.photo} className="spacing---sm" />
                <h6 className="h6 sans">{member.name}</h6>
                <div className="spacing---md">{member.role}</div>
                <div>
                  {member.socials.map((social, index) => (
                    <a
                      className="mr-2"
                      key={`member5-social-${index}`}
                      href={social.url}
                    >
                      {social.label}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="spacing---lg md---spacing---0 w-clearfix">
            {team.members.slice(12, 16).map((member, index) => (
              <div key={`member6-${index}`} className="team-member">
                <Image imageData={member.photo} className="spacing---sm" />
                <h6 className="h6 sans">{member.name}</h6>
                <div className="spacing---md">{member.role}</div>
                <div>
                  {member.socials.map((social, index) => (
                    <a
                      className="mr-2"
                      key={`member6-social-${index}`}
                      href={social.url}
                    >
                      {social.label}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {/* </div><a href="#" className="cta">View Everyone</a></div> */}
        </div>
      </div>
    </div>
  )
}

const AnnouncementBanner = () => (
  <div className="announcement-banner background---black">
    <div
      className="announcement-banner__wrapper"
      style={{ background: "white" }}
    >
      <ThesisIntroducingMezoLogo />
      <Link to="https://mezo.org" className="btn">
        Discover
      </Link>
    </div>
  </div>
)

function IndexPage({ data }) {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AnnouncementBanner />
      <Section1 {...post.frontmatter.hero} />
      <Section2 {...post.frontmatter.asterisk} />
      <Section3 {...post.frontmatter.social} />
      <Section4 {...post.frontmatter.our_work} />
      <Section5 {...post.frontmatter.assurance} />
      <ContactSection {...post.frontmatter.contact} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default IndexPage

export const query = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        hero {
          hero {
            title
            body
          }
          what {
            title
            body
          }
        }
        asterisk {
          title
          contents {
            label
            description
          }
        }
        social {
          tabs {
            label
            url
          }
          contents {
            blurb
            attribute
          }
        }
        our_work {
          title
          body
          projects {
            name
            photo {
              image {
                relativePath
                childImageSharp {
                  gatsbyImageData(width: 1200)
                }
              }
              alt
            }
            logo {
              image {
                relativePath
              }
              alt
            }
            cta {
              url
              title
              icon {
                image {
                  relativePath
                }
                alt
              }
            }
          }
        }
        assurance {
          title
          body
          links {
            url
            icon {
              image {
                relativePath
              }
              alt
            }
            description
          }
        }
        contact {
          join {
            title
            body
            link {
              url
              label
            }
          }
          team {
            title
            members {
              name
              role
              photo {
                image {
                  relativePath
                }
                alt
              }
              socials {
                url
                label
              }
            }
          }
        }
      }
    }
  }
`
